<template>
    <div class="ReportManagement">
        <div class="fromInputs">
            <ul>
                <li>
                    <div class="packingTime imports">
                        <span>装箱时间：</span>
                        <div class="datePicker">
                            <el-date-picker v-model="packingTimeValue" type="daterange" range-separator="~"
                                value-format="yyyy-MM-dd" start-placeholder="请选择起始值" end-placeholder="请选择结束值">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="packingTime" v-show="flagOpen">
                        <span>离港时间：</span>
                        <div class="datePicker">
                            <el-date-picker v-model="departureTimeValue" type="daterange" range-separator="~"
                                value-format="yyyy-MM-dd" start-placeholder="请选择起始值" end-placeholder="请选择结束值">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="packingTime" v-show="flagOpen">
                        <span>到港时间：</span>
                        <div class="datePicker">
                            <el-date-picker v-model="arrivalTimeValue" type="daterange" range-separator="~"
                                value-format="yyyy-MM-dd" start-placeholder="请选择起始值" end-placeholder="请选择结束值">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="packingTime" v-show="flagOpen">
                        <span>到门时间：</span>
                        <div class="datePicker">
                            <el-date-picker v-model="dtoTheDoorValue" type="daterange" range-separator="~"
                                value-format="yyyy-MM-dd" start-placeholder="请选择起始值" end-placeholder="请选择结束值">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="packingTime" v-show="flagOpen">
                        <span>收货人：</span>
                        <div class="datePicker">
                            <el-input v-model="consigneeValue" placeholder="请输入收货人"></el-input>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="packingTime" v-show="flagOpen">
                        <span class="flexs">发货城市：</span>
                        <div class="datePicker">
                            <el-input v-model="shippingCityValue" placeholder="请输入发货城市"></el-input>
                        </div>
                    </div>

                    <div class="packingTime" v-show="flagOpen">
                        <span>收货城市：</span>
                        <div class="datePicker">
                            <el-input v-model="shipToCityValue" placeholder="请输入收货城市"></el-input>
                        </div>
                    </div>

                    <div class="packingTime" v-show="flagOpen">
                        <span>装箱号：</span>
                        <div class="datePicker">
                            <el-input v-model="packingNoValue" placeholder="请输入装箱号"></el-input>
                        </div>

                    </div>
                    <div class="packingTime" v-show="flagOpen">
                        <span>合同号：</span>
                        <div class="datePicker">
                            <el-input v-model="contractNoValue" placeholder="请输入合同号"></el-input>
                        </div>
                    </div>
                    <div class="packingTime" v-show="flagOpen">
                        <span>订单类型：</span>
                        <div class="datePicker">
                            <el-select v-model="orderTypes" placeholder="请选择订单类型">
                                <el-option label="海运订单" value="海运订单"></el-option>
                                <el-option label="铁路订单" value="铁路订单"></el-option>
                                <el-option label="空运订单" value="空运订单"></el-option>
                            </el-select>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="packingTime" v-show="flagOpen">
                        <span class="flexs">预计到港时间：</span>
                        <div class="datePicker">
                            <el-date-picker v-model="estimatedTimeOfArriValue" type="daterange" range-separator="~"
                                value-format="yyyy-MM-dd" start-placeholder="请选择起始值" end-placeholder="请选择结束值">
                            </el-date-picker>
                        </div>
                    </div>

                    <div class="packingTime" v-show="flagOpen">
                        <span>提单号：</span>
                        <div class="datePicker">
                            <el-input v-model="blNoValue" placeholder="请输入提单号"></el-input>
                        </div>
                    </div>


                    <div class="packingTime" v-show="flagOpen">
                        <span>发票号：</span>
                        <div class="datePicker">
                            <el-input v-model="invoiceNoValue" placeholder="请输入发票号"></el-input>
                        </div>
                    </div>

                    <div class="fromBtn packingTime" :class="{ 'flagActive': !flagOpen }">
                        <el-button type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
                        <el-button type="primary" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
                        <el-button type="primary" icon="el-icon-download" @click="handleExport">导出</el-button>
                        <el-button type="primary" :icon="flagOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                            @click="handleopen">{{ flagOpen ? '收起' : '展开' }}</el-button>
                    </div>
                </li>
            </ul>

        </div>

        <div class="tables" v-if="isDetail">
            <div class="tips"><span>默认显示全部历史订单，如需精准查询请筛选条件！</span>
                <div class="shuaxin" @click="handleRefresh">刷新状态</div>
            </div>
            <el-table :data="orderDetailList" style="width: 100%" border :max-height="maxheight"
                :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" :header-row-style="{ height: '50px' }"
                :row-style="{ height: '56px' }">
                <el-table-column prop="customer" label="客户名称" width="200">
                </el-table-column>
                <el-table-column prop="country" label="贸易国家" width="200">
                </el-table-column>
                <el-table-column prop="bookingTime" label="订舱时间" width="200">
                    <template slot-scope="scope">
                        <!-- <span v-show="scope.row.bookingTime">{{ scope.row.bookingTime.split(' ')[0] || '-' }}</span> -->
                        <span v-if="scope.row.bookingTime">{{ scope.row.bookingTime.split(" ")[0] || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="contractNo" label="合同号" width="200">
                </el-table-column>
                <el-table-column prop="billNo" label="提单号/BL NO./运单号" width="200">
                </el-table-column>
                <el-table-column prop="packingNo" label="集装箱号/封号" width="200">

                </el-table-column>
                <el-table-column prop="" label="邮编" width="200">
                    <template slot-scope="scope">
                        <span>{{ scope.row.postalCode }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="交货地点" width="200">
                    <template slot-scope="scope">
                        <span> {{ scope.row.receivingCity }}</span>
                    </template>
                </el-table-column>

                <!-- <el-table-column prop="cupboardNum" label="舱位" width="150">
                    </el-table-column> -->
                <el-table-column prop="deliveryCity" label="装箱地址" width="200">
                </el-table-column>
                <el-table-column prop="pickUpTime" label="装箱日期" width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.pickUpTime">{{ scope.row.pickUpTime.split(' ')[0] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="pkg" label="件数" width="200">
                </el-table-column>
                <el-table-column prop="kgs" label="毛重（KGS)" width="200">
                </el-table-column>
                <el-table-column prop="cbm" label="尺码（CBM)" width="200">
                </el-table-column>
                <el-table-column prop="cupboard" label="箱量（发货柜型）" width="200">
                </el-table-column>
                <el-table-column prop="froms" label="FROM" width="200">
                </el-table-column>
                <el-table-column prop="tos" label="TO" width="200">
                </el-table-column>
                <el-table-column prop="transTime" label=" 实际离港时间 （ATD)" width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.transTime">{{ scope.row.transTime.split(' ')[0] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="expectArrivalTime" label="预计到港时间（ETA)" width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.expectArrivalTime">{{ scope.row.expectArrivalTime.split(' ')[0] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="arrivalTime" label="实际到港时间（ATA）" width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.arrivalTime">{{ scope.row.arrivalTime.split(' ')[0] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="completionTime" label="到门时间 (EMPTY RETURN)" width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.completionTime">{{ scope.row.completionTime.split(' ')[0] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="duration" label="运效(天)" width="200">
                </el-table-column>
                <el-table-column prop="invoiceNo" label="发票号" width="200">
                </el-table-column>
                <el-table-column prop="orderType" label="运输方式" width="200">
                </el-table-column>
                <el-table-column prop="operator" label="操作员" width="200">
                </el-table-column>
                <el-table-column prop="orderStatusNote" label="状态" width="200">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
import moment from 'moment';
export default {
    name: "HistoricalReport",
    data() {
        return {
            flagOpen: false,
            maxheight: 500,
            packingTimeValue: [], //装箱时间
            departureTimeValue: "", //离港时间
            estimatedTimeOfArriValue: "",//预计到港时间
            arrivalTimeValue: "",//到港时间
            dtoTheDoorValue: "", //到门时间
            consigneeValue: "",//收货人
            shippingCityValue: "",//发货城市
            shipToCityValue: "",//收货城市
            contractNoValue: "",//合同号
            orderTypes: '',
            blNoValue: "",//提单号
            packingNoValue: "",//装箱号
            invoiceNoValue: "",//发票号
            orderDetailList: [],
            isDetail: true,
        }
    },
    created() {
        this.getReportList();

    },

    methods: {
        handleRefresh() {
            this.isDetail = false
            this.$nextTick(() => {
                this.isDetail = true;
            })
        },

        getReportList() {
            var token = sessionStorage.getItem("token")
            let startDate = moment().subtract(30, "days").format('YYYY-MM-DD')
            let endDate = moment().format('YYYY-MM-DD');
            // this.packingTimeValue[0] = startDate;
            // this.packingTimeValue[1] = endDate;
            console.log(this.packingTimeValue);
            console.log(startDate);
            console.log(endDate);
            axios.get(`${baseUrl}/history/reportList`, {

                params: {
                    pick_up_time_begin: this.packingTimeValue[0],
                    pick_up_time_end: this.packingTimeValue[1]
                },
                headers: {
                    'authorization': token
                }
            }).then(res => {
                this.orderDetailList = res.data.result;
                console.log(res.data);
            })
        },
        handleopen() {  //展开
            console.log('展开');
            this.flagOpen = !this.flagOpen
        },

        handleQuery() {  //查询按钮
            console.log(this.dtoTheDoorValue);
            if (this.packingTimeValue) {
                var packingTimeValue1 = this.packingTimeValue[0];  //装箱时间起始值
                var packingTimeValue2 = this.packingTimeValue[1];  //装箱时间结束值
            } else {
                var packingTimeValue1 = '';  //装箱时间起始值
                var packingTimeValue2 = '';  //装箱时间结束值
            }

            if (this.departureTimeValue) {
                var departureTimeValue1 = this.departureTimeValue[0]; //离岗时间起始值
                var departureTimeValue2 = this.departureTimeValue[1]; //离岗时间结束值
            } else {
                var departureTimeValue1 = ''; //离岗时间起始值
                var departureTimeValue2 = ''; //离岗时间结束值
            }

            if (this.estimatedTimeOfArriValue) {
                var estimatedTimeOfArriValue1 = this.estimatedTimeOfArriValue[0]; //预计到港时间
                var estimatedTimeOfArriValue2 = this.estimatedTimeOfArriValue[1]; //预计到港时间
            } else {
                var estimatedTimeOfArriValue1 = ''; //预计到港时间
                var estimatedTimeOfArriValue2 = ''; //预计到港时间
            }

            if (this.arrivalTimeValue) {
                var arrivalTimeValue1 = this.arrivalTimeValue[0];//到港时间
                var arrivalTimeValue2 = this.arrivalTimeValue[1];//到港时间
            } else {
                var arrivalTimeValue1 = '';//到港时间
                var arrivalTimeValue2 = '';//到港时间
            }

            if (this.dtoTheDoorValue) {
                var dtoTheDoorValue1 = this.dtoTheDoorValue[0]; //到门时间
                var dtoTheDoorValue2 = this.dtoTheDoorValue[1]; //到门时间
            } else {
                var dtoTheDoorValue1 = ''; //到门时间
                var dtoTheDoorValue2 = ''; //到门时间
            }
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/history/reportList`, {
                params: {
                    pick_up_time_begin: packingTimeValue1 || "",  //装箱时间起始值
                    pick_up_time_end: packingTimeValue2 || "",    //装箱时间结束值
                    trans_time_begin: departureTimeValue1 || "",  //离岗时间起始值
                    trans_time_end: departureTimeValue2 || "",    //离岗时间结束值
                    expect_arrival_time_begin: estimatedTimeOfArriValue1 || "",  //预计到港时间
                    expect_arrival_time_end: estimatedTimeOfArriValue2 || "",
                    arrival_time_begin: arrivalTimeValue1,  //到港时间
                    arrival_time_end: arrivalTimeValue2,
                    completion_time_begin: dtoTheDoorValue1,   //到门时间
                    completion_time_end: dtoTheDoorValue2,
                    froms: this.shippingCityValue,//发货城市
                    tos: this.shipToCityValue,//收货城市
                    contract_no: this.contractNoValue,//合同号
                    bill_no: this.blNoValue,//提单号
                    packing_no: this.packingNoValue, //装箱号
                    invoice_no: this.invoiceNoValue,//发票号
                    customer: this.consigneeValue, //收货人
                    orderType: this.orderTypes,//订单类型	

                },
                headers: {
                    'authorization': token
                }
            }).then(res => {
                console.log(res.data);
                this.orderDetailList = res.data.result;
            })
        },
        handleReset() { //重置
            this.packingTimeValue = this.departureTimeValue = this.estimatedTimeOfArriValue = this.arrivalTimeValue = this.dtoTheDoorValue = this.shippingCityValue = this.shipToCityValue = this.contractNoValue = this.blNoValue = this.packingNoValue = this.invoiceNoValue = this.consigneeValue = this.orderTypes = ''
        },

        handleExport() {  //导出   
            var token = sessionStorage.getItem("token");
            if (this.packingTimeValue) {
                var packingTimeValue1 = this.packingTimeValue[0];  //装箱时间起始值
                var packingTimeValue2 = this.packingTimeValue[1];  //装箱时间结束值
            } else {
                var packingTimeValue1 = '';  //装箱时间起始值
                var packingTimeValue2 = '';  //装箱时间结束值
            }

            if (this.departureTimeValue) {
                var departureTimeValue1 = this.departureTimeValue[0]; //离岗时间起始值
                var departureTimeValue2 = this.departureTimeValue[1]; //离岗时间结束值
            } else {
                var departureTimeValue1 = ''; //离岗时间起始值
                var departureTimeValue2 = ''; //离岗时间结束值
            }

            if (this.estimatedTimeOfArriValue) {
                var estimatedTimeOfArriValue1 = this.estimatedTimeOfArriValue[0]; //预计到港时间
                var estimatedTimeOfArriValue2 = this.estimatedTimeOfArriValue[1]; //预计到港时间
            } else {
                var estimatedTimeOfArriValue1 = ''; //预计到港时间
                var estimatedTimeOfArriValue2 = ''; //预计到港时间
            }

            if (this.arrivalTimeValue) {
                var arrivalTimeValue1 = this.arrivalTimeValue[0];//到港时间
                var arrivalTimeValue2 = this.arrivalTimeValue[1];//到港时间
            } else {
                var arrivalTimeValue1 = '';//到港时间
                var arrivalTimeValue2 = '';//到港时间
            }

            if (this.dtoTheDoorValue) {
                var dtoTheDoorValue1 = this.dtoTheDoorValue[0]; //到门时间
                var dtoTheDoorValue2 = this.dtoTheDoorValue[1]; //到门时间
            } else {
                var dtoTheDoorValue1 = ''; //到门时间
                var dtoTheDoorValue2 = ''; //到门时间
            }

            axios.get(`${baseUrl}/history/exportExcel`, {
                params: {
                    pick_up_time_begin: packingTimeValue1 || "",  //装箱时间起始值
                    pick_up_time_end: packingTimeValue2 || "",    //装箱时间结束值
                    trans_time_begin: departureTimeValue1 || "",  //离岗时间起始值
                    trans_time_end: departureTimeValue2 || "",    //离岗时间结束值
                    expect_arrival_time_begin: estimatedTimeOfArriValue1 || "",  //预计到港时间
                    expect_arrival_time_end: estimatedTimeOfArriValue2 || "",
                    arrival_time_begin: arrivalTimeValue1,  //到港时间
                    arrival_time_end: arrivalTimeValue2,
                    completion_time_begin: dtoTheDoorValue1,   //到门时间
                    completion_time_end: dtoTheDoorValue2,
                    froms: this.shippingCityValue,//发货城市
                    tos: this.shipToCityValue,//收货城市
                    contract_no: this.contractNoValue,//合同号
                    orderTypes: "",//订单类型
                    bill_no: this.blNoValue,//提单号
                    packing_no: this.packingNoValue, //装箱号
                    invoice_no: this.invoiceNoValue,//发票号
                    customer: this.consigneeValue, //收货人
                    orderType: this.orderTypes,//订单类型	
                },
                headers: {
                    'authorization': token,
                    responseType: 'blob',
                    'Content-type': 'application/json;'
                },
                responseType: 'blob',
            })
                .then(res => {
                    console.log("#", res);
                    const xlsx = 'application/vnd.ms-excel'
                    var blob = new Blob([res.data], { type: xlsx });
                    var downloadElement = document.createElement('a');
                    console.log(blob);
                    var href = window.URL.createObjectURL(blob); // 创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '报表.xlsx'; // 下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); // 点击下载
                    document.body.removeChild(downloadElement); // 下载完成移除元素
                    window.URL.revokeObjectURL(href); // 释放掉blob对象
                })

        },
    },
}
</script>

<style lang="scss" scoped>
.flagActive {
    margin-top: 0 !important;
}

::v-deep .el-select {
    width: 100%;
}

// ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
//     width: 649px;
//     //     /*滚动条宽度*/
//     height: 8px;
//     //     /*滚动条高度*/
// }

// ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
//     border-radius: 0px;
//     /*滚动条的背景区域的圆角*/

//     background: rgba(153, 153, 153, 0.2);
//     /*滚动条的背景颜色*/
// }

// ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
//     border-radius: 4px;
//     /*滚动条的圆角*/

//     background: #999999;
//     /*滚动条的背景颜色*/
// }
::v-deep .el-table .el-table__cell {
    padding: 0;
}

.ReportManagement {
    // max-width: 1526px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding-top: 24px;
    margin: 0 auto;
    // min-height: 690px;

    .fromInputs {
        padding: 0 24px;

        .fromInputsTop {
            display: flex;
            justify-content: flex-start;

            .imports {
                span {
                    width: 70px;
                }
            }

            .packingTime {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 10px;

                span {
                    // flex: 1;
                    max-width: 100px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    white-space: nowrap;
                    text-align: right;
                }

                .datePicker {
                    width: 284px;
                    height: 40px;

                    .el-date-editor {
                        width: 100%;
                        height: 100%;

                    }
                }
            }
        }

        .fromInputsCenter {
            display: flex;
            justify-content: flex-start;
            margin-top: 24px;

            .packingTime {
                // width: 354px;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 10px;


                span {
                    // flex: 1;
                    max-width: 100px;
                    white-space: nowrap;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    text-align: right;
                }

                .datePicker {
                    width: 284px;
                    height: 40px;

                    .el-date-editor {
                        width: 100%;
                        height: 100%;

                    }
                }

                .datePickerSmall {
                    width: 140px;

                    .el-date-editor {
                        width: 100%;
                        height: 100%;

                    }
                }
            }
        }

        .fromInputsBottom {
            display: flex;
            justify-content: flex-start;
            margin-top: 24px;

            .packingTime {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-left: 10px;


                span {
                    flex: 1;
                    text-align: right;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                }

                .datePicker {
                    width: 284px;
                    height: 40px;

                    .el-date-editor {
                        width: 100%;
                        height: 100%;

                    }
                }
            }
        }

        .fromInputsBottom2 {
            display: flex;
            justify-content: flex-start;
            margin-top: 24px;
            align-items: center;

            .packingTime {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-left: 10px;


                span {
                    flex: 1;
                    text-align: right;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                }

                .datePicker {
                    width: 284px;
                    height: 40px;

                    .el-date-editor {
                        width: 100%;
                        height: 100%;

                    }
                }
            }

            .fromBtn {
                // margin-left: 24px;
                display: flex;
                justify-content: flex-end;

                .el-button:nth-child(1) {
                    background-color: #FFFFFF;
                    border: 0;
                    color: #40a9ff;
                }
            }
        }

        ul {
            display: flex;
            justify-content: space-between;
            // padding: 0 24px;

            li {

                .packingTime {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 24px;

                    // margin-left: 10px;
                    span {
                        // flex: 1;
                        // max-width: 100px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        white-space: nowrap;
                        text-align: right;
                    }

                    .datePicker {
                        width: 284px;
                        height: 40px;

                        .el-date-editor {
                            width: 100%;
                            height: 100%;

                        }
                    }
                }

                .packingTime:nth-child(1) {
                    margin-top: 0;
                }

                .fromBtn {
                    // margin-left: 24px;
                    display: flex;
                    justify-content: flex-end;

                    .el-button:nth-child(4) {
                        background-color: #FFFFFF;
                        border: 0;
                        color: #40a9ff;
                    }
                }
            }
        }


    }

    .tables {
        width: 100%;
        margin-top: 60px;
        padding: 0 24px 32px;
        min-height: 500px;

        ::v-deep .nana {
            text-align: center;
        }

        ::v-deep .el-table_1_column_11 {
            text-align: center;
        }

        .el-button {
            // width: 72px;
            height: 24px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
            // line-height: 24px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            padding: 6px 12px;
        }

        .el-button:nth-child(2) {
            background: #D4252C;
            box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
        }


        ::v-deep .is-leaf {
            font-size: 14px;
            font-weight: 400;
            color: #707070;
        }

        ::v-deep .el-table__row {
            height: 84px;

            .el-table__cell {
                font-size: 14px;
                // font-weight: bold;
                color: #333333;

                .cell {
                    height: 24px;

                }
            }
        }


    }

    .tips {
        display: flex;
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;

        .shuaxin {
            width: 72px;
            height: 24px;
            background: #1890FF;
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 24px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            margin-left: 32px;
            cursor: pointer;
        }
    }
}
</style>